










import { defineComponent } from "@vue/composition-api";

export default defineComponent({
  components: {
    OPosterConfig: () => import('@/components/organisms/event/posterSession/o-poster-config.vue')
  },
  setup() {
    return {};
  },
});
